import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import "@fontsource/ubuntu";
import GMap from "../components/GMap";
// import Manager from "../assets/Manager.jpeg";
// import Sales1 from "../assets/Sales1.jpg";
// import Sales2 from "../assets/Sales2.jpeg";
import Whatsapp from "../assets/whatsapp.svg";
import Email from "../assets/email.svg";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UpperBox = styled.div`
  padding: 2.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryColor};
`;
const Title = styled.h2`
  width: 75%;
  text-align: start;
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 900;
  color: ${(props) => props.theme.body};
  text-transform: capitalize;
  @media (max-width: 768px) {
    width: 95%;
    font-size: 1.5em;
  }
`;

const LowerBox = styled.div`
  margin: 1rem 0;
  height: auto;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 95%;
  }
`;

const StaffContainer = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Department = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const DepartmentName = styled.h3`
  text-align: center;
  width: 100%;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};
  &.labelText {
    font-weight: 900;
  }
  @media (max-width: 768px) {
    text-align: left;
    padding-left: 1rem;
  }
`;

const ContactCart = styled.div`
  width: 45%;
  padding: 1rem;
  border: 1px solid #bbb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 2px solid ${(props) => props.theme.primaryColor};
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
`;
const VerticalBox = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ContactCartHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContactContainer = styled.div`
  margin: 1rem 0;
  height: auto;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 95%;
    gap: 2rem;
  }
`;
const ProfileInfo = styled.div`
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
// const ProfilePic = styled.img`
//   width: 10rem;
//   height: 10rem;
//   object-fit: cover;
// `;

const ContactName = styled.p`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  font-weight: bold;
  @media (max-width: 768px) {
    width: 95%;
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const ContactPosition = styled.p`
  padding: 3px 6px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.body};
  font-size: ${(props) => props.theme.fontmd};
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: bold;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${(props) => props.theme.secondaryColor};
  gap: 7px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 95%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;
const ContactIcon = styled.img`
  height: 20px;
  width: 20px;
  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
  }
`;

const Box = styled.div`
  width: 50%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const ContactFormWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const ContactForm = styled.form`
  align-self: flex-end;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 95%;
    align-self: flex-start;
  }
`;

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 500;
  margin-bottom: 0.4rem;
  font-family: "Ubuntu", sans-serif;
`;

const Input = styled.input`
  padding: 0.75rem;
  font-size: ${(props) => props.theme.fontmd};
  color: #333;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  border-radius: 5px;
  width: 100%;
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  font-size: ${(props) => props.theme.fontmd};
  color: #333;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  border-radius: 4px;
  width: 100%;
  width: 100%;
  height: 200px;
`;
const SubmitEmail = styled.input`
  align-self: flex-start;
  display: inline-block;
  padding: ${(props) => props.theme.actionButtonPadding};
  outline: none;
  border: none;
  font-size: ${(props) => props.theme.fontmd};
  font-weight: 600;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.body};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const ContactInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactInfo = styled.div`
  width: 90%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 95%;
  }
`;

const ContactLabel = styled.h3`
  text-align: start;
  width: 100%;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 600;
  color: ${(props) => props.theme.text};
  &.labelText {
    font-weight: 900;
  }
`;

const ContactText = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.fontsm};
`;
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_zrm0h7i",
        "template_srnqihw",
        e.target,
        "WOZGON65T_QDbxr7R"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Section id="contact">
      <UpperBox>
        <Title>Contact Us</Title>
      </UpperBox>
      <LowerBox>
        <StaffContainer>
          <Department>
            <DepartmentName>Company Managment</DepartmentName>
            <VerticalBox>
              <ContactCart>
                <ContactCartHeader>
                  {/* <ProfilePic src={Manager}></ProfilePic> */}
                  <ProfileInfo>
                    {" "}
                    <ContactName>Raouf Benmoussa</ContactName>
                    <ContactPosition>CEO</ContactPosition>
                    <ContactInfoWrapper>
                      <InfoItem>
                        <ContactIcon src={Whatsapp}></ContactIcon>{" "}
                        <a href="tel:+48 508 788 359">+48 508 788 359</a>
                      </InfoItem>
                      <InfoItem>
                        <ContactIcon src={Email}></ContactIcon>{" "}
                        <a href="mailto:contact@fmcgmoment.pl">
                          contact@fmcgmoment.pl
                        </a>
                      </InfoItem>
                    </ContactInfoWrapper>
                  </ProfileInfo>
                </ContactCartHeader>
              </ContactCart>
            </VerticalBox>
          </Department>
          <Department>
            <DepartmentName>Sales Department</DepartmentName>
            <VerticalBox>
              <ContactCart>
                <ContactCartHeader>
                  {/* <ProfilePic src={Sales1}></ProfilePic> */}
                  <ProfileInfo>
                    <ContactName>Imad Bensenouci</ContactName>
                    <ContactPosition>Sales Manager</ContactPosition>
                    <ContactInfoWrapper>
                      <InfoItem>
                        <ContactIcon src={Whatsapp}></ContactIcon>{" "}
                        <a href="tel:+48 786 677 411">+48 786 677 411</a>
                      </InfoItem>
                      <InfoItem>
                        <ContactIcon src={Email}></ContactIcon>{" "}
                        <a href="mailto:imad@fmcgmoment.pl">
                          imad@fmcgmoment.pl
                        </a>
                      </InfoItem>
                    </ContactInfoWrapper>
                  </ProfileInfo>
                </ContactCartHeader>
              </ContactCart>
              <ContactCart>
                <ContactCartHeader>
                  {/* <ProfilePic src={Sales2}></ProfilePic> */}
                  <ProfileInfo>
                    <ContactName>Hossam Abdalla</ContactName>
                    <ContactPosition>Sales Manager</ContactPosition>
                    <ContactInfoWrapper>
                      <InfoItem>
                        <ContactIcon src={Whatsapp}></ContactIcon>{" "}
                        <a href="tel:+48 690 251 331">+48 690 251 331</a>
                      </InfoItem>
                      <InfoItem>
                        <ContactIcon src={Email}></ContactIcon>{" "}
                        <a href="mailto:hossam@fmcgmoment.pl">
                          hossam@fmcgmoment.pl
                        </a>
                      </InfoItem>
                    </ContactInfoWrapper>
                  </ProfileInfo>
                </ContactCartHeader>
              </ContactCart>
              <ContactCart>
                <ContactCartHeader>
                  {/* <ProfilePic src={Sales1}></ProfilePic> */}
                  <ProfileInfo>
                    <ContactName>MOHAMMED ABDERRAOUF BACHIR</ContactName>
                    <ContactPosition>Sales Manager</ContactPosition>
                    <ContactInfoWrapper>
                      <InfoItem>
                        <ContactIcon src={Whatsapp}></ContactIcon>{" "}
                        <a href="tel:+48 451 553 836">+48 451 553 836</a>
                      </InfoItem>
                      <InfoItem>
                        <ContactIcon src={Email}></ContactIcon>{" "}
                        <a href="mailto:sales@fmcgmoment.pl">
                          sales@fmcgmoment.pl
                        </a>
                      </InfoItem>
                    </ContactInfoWrapper>
                  </ProfileInfo>
                </ContactCartHeader>
              </ContactCart>

              <ContactCart>
                <ContactCartHeader>
                  {/* <ProfilePic src={Sales1}></ProfilePic> */}
                  <ProfileInfo>
                    <ContactName>AHMED YOUNES BENMOUSSA</ContactName>
                    <ContactPosition>Sales Manager</ContactPosition>
                    <ContactInfoWrapper>
                      <InfoItem>
                        <ContactIcon src={Whatsapp}></ContactIcon>{" "}
                        <a href="tel:+48 572 333 361">+48 572 333 361</a>
                      </InfoItem>
                      <InfoItem>
                        <ContactIcon src={Email}></ContactIcon>{" "}
                        <a href="mailto:younes@fmcgmoment.pl">
                          younes@fmcgmoment.pl
                        </a>
                      </InfoItem>
                    </ContactInfoWrapper>
                  </ProfileInfo>
                </ContactCartHeader>
              </ContactCart>
              <ContactCart>
                <ContactCartHeader>
                  {/* <ProfilePic src={Sales1}></ProfilePic> */}
                  <ProfileInfo>
                    <ContactName>TOUMI MOUHAND</ContactName>
                    <ContactPosition>Sales Manager</ContactPosition>
                    <ContactInfoWrapper>
                      <InfoItem>
                        <ContactIcon src={Whatsapp}></ContactIcon>{" "}
                        <a href="tel:+48 786 514 867">+48 786 514 867</a>
                      </InfoItem>
                      <InfoItem>
                        <ContactIcon src={Email}></ContactIcon>{" "}
                        <a href="mailto:tommy@fmcgmoment.pl">
                          tommy@fmcgmoment.pl
                        </a>
                      </InfoItem>
                    </ContactInfoWrapper>
                  </ProfileInfo>
                </ContactCartHeader>
              </ContactCart>
            </VerticalBox>
          </Department>
        </StaffContainer>
        <ContactContainer>
          <Box>
            <ContactInfoWrapper>
              <ContactInfo>
                <ContactLabel>Address</ContactLabel>
                <ContactText>Pozioma 4, 15-558 Białystok, Poland</ContactText>
              </ContactInfo>
              <ContactInfo>
                <GMap className="map" />
              </ContactInfo>
            </ContactInfoWrapper>
          </Box>
          <Box>
            <ContactFormWrapper ref={form} onSubmit={sendEmail}>
              <ContactForm onSubmit={(event) => event.preventDefault()}>
                <FormGroup>
                  <ContactLabel className="labelText">Reach Us</ContactLabel>
                </FormGroup>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type="text" name="userName" required />
                </FormGroup>
                <FormGroup>
                  <Label>Email</Label>
                  <Input type="email" name="userEmail" required />
                </FormGroup>
                <FormGroup>
                  <Label>Message</Label>
                  <Textarea name="userMessage" required />
                </FormGroup>
                <SubmitEmail type="submit" value="Send Message" />
              </ContactForm>
            </ContactFormWrapper>
          </Box>
        </ContactContainer>
      </LowerBox>
    </Section>
  );
};

export default Contact;
